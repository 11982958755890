import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Switch, Route, useRouteMatch } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Grid, Typography, CircularProgress, Divider, useTheme, BottomNavigation, BottomNavigationAction, Link } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AppsIcon from '@material-ui/icons/Apps';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useSnackbar } from 'notistack';
import logo from '../UPG_RGB_Landscape_White.svg'
import { IDownloads } from 'shared';
import Downloads from './Downloads';
import RenderDownload from './RenderDownload';
import RenderElk from './RenderElk';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Body: React.FC = () => {

  // TODO Fetch from actual user
  const hasArchivePermission = true;

  const [downloads, setDownloads] = useState<IDownloads>();
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const fetchDownloads = React.useCallback(() => {
    const fetchData = async () => {
      try {
        let data = await fetch('/api/v1/downloads/', {
          headers: {
            "Content-Type": "application/json; charset=UTF-8"
          },
          method: "GET"
        });
        if (!data.ok) {
          setDownloads(undefined);
          enqueueSnackbar(`Unable to retrieve download listing from server - ${data.statusText} (${data.status})`, { variant: 'error' });
        } else {
          let result = await data.json() as IDownloads;
          if (result) {
            setDownloads(result);
          }
        }
      } catch (error) {
        enqueueSnackbar(`Unable to retrieve download listing from server - ${error}`, { variant: 'error' });
      }
    }
    fetchData();
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchDownloads();
  }, [fetchDownloads]);

  return (
    <Grid container >
      <Grid item xs={12}>
        <AppBar position="static">
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <RouterLink to="/" style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}>
                    <AppsIcon fontSize="large" />
                  </RouterLink>
                </IconButton>
                <RouterLink to="/" style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}>
                  <img src={logo} alt="Unifiedpost Download Center" width="220" />
                </RouterLink>
              </Toolbar>
            </Grid>
            <Grid item xs={4} alignContent="center">
              <Typography variant='h4'>Download Center</Typography>
            </Grid>
          </Grid>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          {hasArchivePermission && (
            <Grid item xs={10}>
              <Alert severity="warning" >You have admin permissions and can access old downloads.</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Switch>
              <Route exact path={'/404'}>
                <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Page not found.</h2>
                <Typography>These are not the files you are looking for</Typography>
              </Route>
              <Route path="*">
                {downloads ? (
                  <Switch>
                    <Route exact path={'/elk'}>
                      <RenderElk downloads={downloads} />
                    </Route>
                    <Route path={`${path}/:category/:download`}>
                      <RenderDownload downloads={downloads} hasArchivePermission={hasArchivePermission} />
                    </Route>
                    <Route exact path={`${path}`}>
                      <Downloads downloads={downloads}></Downloads>
                    </Route>
                  </Switch>
                ) : (
                    <CircularProgress />
                  )}
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} style={{ padding: '20px' }}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <p>Copyright Unifiedpost group - all rights reserved.</p>
                </Grid>
                <Grid item xs={2}>
                  <BottomNavigation>
                    <BottomNavigationAction label="Facebook" value="recents" icon={<FacebookIcon />} />
                    <BottomNavigationAction label="Linkedin" value="favorites" icon={<LinkedInIcon />} />
                    <BottomNavigationAction label="Twitter" value="nearby" icon={<TwitterIcon />} />
                  </BottomNavigation>
                </Grid>
                <Grid item xs={5}>
                  <p><Link href="https://www.unifiedpost.com/privacy-cookie-policy">Privacy Statement</Link></p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Grid >
  );
}

export default Body;
