import React from 'react';
import { Grid, List, ListItem, ListItemText, ListItemIcon, Paper, Divider, useTheme, Typography, Link } from '@material-ui/core';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { IDownloads } from 'shared';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(() =>
    React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
      <RouterLink to={to} ref={ref} {...itemProps} />
    )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primaryTypographyProps={{ color: 'textPrimary' }} primary={primary} />
      </ListItem>
    </li>
  );
}

const Downloads: React.FC<{ downloads: IDownloads }> = (props) => {

  const theme = useTheme();

  if (!props?.downloads) {
    return (<div></div>);
  }

  return (
    <React.Fragment>
      <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Available Downloads</h2>
      <Grid container spacing={3} style={{ padding: '20px' }}>
        {Object.keys(props.downloads).sort().map((tag, tagindex) => (
          <Grid key={`tag-${tagindex}`} item xs={3}>
            <Paper variant="outlined" square elevation={3} style={{ height: '100%' }}>
              <Grid container>
                <Grid item style={{ width: '100%' }}>
                  <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>{tag}</h2>
                  <Divider variant="middle" />
                  <Grid container justify="center">
                    <Grid item>
                      <List>
                        {Object.keys(props.downloads[tag]).map((download, index: number) => (
                          <ListItemLink key={`download-${tagindex}.${index}`} to={`/downloads/${encodeURIComponent(tag)}/${encodeURIComponent(download)}`} primary={download} />
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container justify="center" style={{ paddingTop: '20px' }}>
        <Grid item xs={10}>
          <Typography paragraph>
            Need a license key ? Contact our <Link href="mailto:support@unifiedpost.com">customer services departement</Link>.
          </Typography>
          <Typography paragraph>
            Looking for Emergency License Keys for disaster recovery? Go <RouterLink to="elk">here</RouterLink>.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Downloads;