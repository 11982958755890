import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import CssBaseLine from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import Body from './components/Body';

const App: React.FC = () => {

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63c6bf',
        main: '#322b78',
        dark: '#211c4e',
        contrastText: '#ffffff',
      }
    },
    typography: {
      fontFamily: [
        'Lato',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <div className="App">
      <React.Fragment>
        <CssBaseLine />
        {
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={5}>
                <Router>
                  <Switch>
                    <Route path="/404">
                      <Body/>
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/downloads" />
                    </Route>
                    <Route path="/downloads">
                      <Body/>
                    </Route>
                    <Route path="/elk">
                      <Body/>
                    </Route>
                    <Route path="*">
                      <Redirect to="404" />
                    </Route>
                  </Switch>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </React.Fragment>
        }
      </React.Fragment>
    </div >
  );
}

export default App;
