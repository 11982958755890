import React, { useState } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select, useTheme, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import { IDownloads } from 'shared';

const RenderElk: React.FC<{ downloads: IDownloads }> = (props) => {

  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState<string>();

  if (!props.downloads) {
    return (<div></div>);
  }

  const elks: { title: string, items: string[] }[] = [];
  Object.entries(props.downloads).forEach(category => {
    Object.entries(category[1]).forEach(title => {
      console.log('Processing ', category[0], ' - ', title[0], ' : ', title[1].elkpattern);
      if (title[1].elkpattern) {
        elks.push({ title: title[0], items: title[1].elkpattern });
      }
    });
  });

  return (
    <React.Fragment>
      <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Emergency License Keys</h2>
      <Grid container justify="flex-start" spacing={3} style={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Typography align="left" paragraph>
            Certain On Premise Products use License Keys to protect their usage based on your entitlements, which will
            be issued by customer services tailored to your needs and purchases. Should you loose access to your license,
            plan a migration, change your purchase or subscription you can always contact us to replace the key.
          </Typography>
          <Typography align="left" paragraph>
            However in certain situations such as disaster recovery, the inherent delay for requesting a new key or providing
            us the necessary customer information, may be too much of a hurdle. For such cases you can download a temporary
            emergency key below to help you get back live in the shortest time possible.
          </Typography>
          <Typography align="left" paragraph>
            Please note that these keys only work for a short period, their usage is traced and they are not tailored to your purchases.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <InputLabel id="elk-select-label">Select a Product</InputLabel>
            <Select autoWidth labelId="elk-select-label" id="elk-select" onChange={e => setSelectedProduct(e.target.value as string)} value={selectedProduct ? selectedProduct : elks?.[0]?.title}>
              {elks.map(item => (<MenuItem key={item.title} value={item.title}>{item.title}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ padding: '20px' }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>License Key Files</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProduct && elks.find((value) => value.title === selectedProduct)?.items?.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row" align="right">
                      <a href={value}>{value.includes('/') ? value.substr(0, value.lastIndexOf('/')) : value}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography align="left" paragraph>
            Consult the product documentation on how to install the license keys.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RenderElk;