import React, { useState } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select, useTheme, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { IDownloads } from 'shared';
import ReactMarkdown from 'react-markdown';

// TODO fetch actual content
const createMD = (version: string) => {
    return `
# Scriptura Engage 19.04 LTS
Scriptura 19.04 is the latest brand new Long Term Stable release. Recommended for projects that are starting out or are planning an upgrade. Will receive regular maintenance updates. Roll up with stabilisation of all new features release in 17.07, 17.10, 18.01 and 18.04

| File | OS | Version | Date | MD5 | 
| ----- | ----- | ------| ------| ----|
| [Scriptura Engage Windows 64 bit installer](downloads/scr/releases/${version}/Scriptura-Engage-${version}-Full-Windows-64bit-setup.exe) | Windows 7SP1/8/8.1/10/2008/2008R2/2012/2016/2019 64 bit only (x86_64/AMD64) | ${version} | 16 Jan 2020 | 5673530ae01e1297808a9b30b3e22975 |
| [Scriptura Engage Cross platform installer](downloads/scr/releases/${version}/Scriptura-Engage-${version}-setup.jar) | All supported platforms | ${version} | 16 Jan 2020 | e6d5fd5a9a3131e2655e1ddd4484e965 |
| [Scriptura Sample LiveDocs Document Flows](downloads/scr/releases/${version}/scriptura-livedocs-scriptura-project-release-${version}.zip) | All supported platforms | ${version} | 16 Jan 2020 | cfa79f98fea21ba6851a6aba92e0905a |

Read the product [Documentation](https://docs.scripturaengage.com/en/index.html) for more information on installation and usage.
`};

const interceptDownloadUrls = (uri: string): string => {
    if (uri.startsWith('download')) {
        console.log("Rewriting download link " + uri);
        return "https://downloads.unifiedpost.com/" + uri;
    }
    return uri;
}

const RenderDownload: React.FC<{ downloads: IDownloads, hasArchivePermission: boolean }> = (props) => {

    const { category, download } = useParams();
    const theme = useTheme();
    const [activeVersion, setActiveVersion] = useState<string>();

    if (!category || !download || !props?.downloads) {
        return (<div></div>);
    }
    const settorender = props.downloads?.[category]?.[download];
    let active = settorender?.items?.filter(value => value?.key === settorender.active);
    if (active.length === 0) {
      // older versions don't have active properties
      active.push(settorender?.items?.[0]);
    }

    return (
        <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Download {download}</h2>
            {props.hasArchivePermission &&
                (<Grid container justify="flex-end" style={{ padding: '20px' }}>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="dashboard-select-label">Select Another Version</InputLabel>
                            <Select labelId="dashboard-select-label" id="dashboard-select" onChange={e => setActiveVersion(e.target.value as string)} value={activeVersion ? activeVersion : active?.[0]?.key}>
                                {settorender?.items?.map(item => (<MenuItem key={item.key} value={item.key}>{item.version}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>)
            }
            <Grid container justify="flex-start" spacing={3} style={{ padding: '20px' }}>
                <Grid item xs={12}>
                  <Paper variant="outlined" square elevation={3}>
                    <ReactMarkdown source={createMD((activeVersion ? activeVersion : active?.[0]?.version) || '')} transformLinkUri={interceptDownloadUrls}></ReactMarkdown>
                  </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default RenderDownload;